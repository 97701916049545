import React from 'react';
import { graphql } from 'gatsby';
import PageBody from '../components/pages/page/PageBody';
import { IPageData } from '../components/pages/page/types';

const Page: React.FC<IPageData> = (props) => <PageBody {...props} />;

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      hasSubmenu
      showTwitterSection
      seoTitle
      seoDescription
      seoImage {
        asset {
          fluid {
            src
          }
        }
      }
      showSectionSlider
      slides {
        _key
        tag
        title
        setWhiteColorText
        buttonText
        buttonLink
        description
        image {
          asset {
            fluid(maxWidth: 1712) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
      carouselItems {
        _key
        heading
        _rawBody
      }
      carouselQuotes {
        _key
        heading
        quotes {
          _key
          authorName
          positionName
          quote
          title
          image {
            asset {
              fluid(maxWidth: 1712) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
      infoBanner {
        ... on SanityInfoBanner {
          _type
          link {
            ... on SanityAddressEmail {
              _type
              email
              title
            }
            ... on SanityMenuItem {
              _type
              link
              title
              openNewTab
              nofollow
            }
          }
          quote
          subtitle
          title
        }
        ... on SanityInfoBannerPopup {
          _type
          title
          quote
          buttonText
          popupHeading
        }
      }
      sections {
        ... on SanityHeadingContentHighlight {
          _key
          _type
          heading
          _rawBody
        }
        ... on SanitySingleImage {
          _key
          _type
          mainImage {
            asset {
              fluid(maxWidth: 772) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        ... on SanityVideo {
          _type
          _key
          isAutoplay
          video {
            asset {
              url
            }
          }
          placeholderImage {
            asset {
              fluid(maxWidth: 1712) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        ... on SanityVideoUrl {
          videoUrl
          isAutoplay
          _type
          _key
          placeholderImage {
            asset {
              fluid(maxWidth: 1712) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        ... on SanityContentText {
          _key
          _type
          _rawBody
          text
        }
        ... on SanityContentButton {
          _key
          _type
          _rawBody
          button {
            title
            link
            openNewTab
            nofollow
          }
        }
        ... on SanityCaseStudiesSection {
          _key
          _type
          title
          button {
            title
            openNewTab
            nofollow
            link
            _type
          }
          caseStudies {
            mainImage {
              asset {
                fluid(maxWidth: 1712) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            mainVideo {
              asset {
                id
                url
              }
            }
            id
            darkBackground
            clientName
            title
            slug {
              current
            }
          }
        }
        ... on SanityInsightsSection {
          _key
          _type
          title
          button {
            link
            nofollow
            openNewTab
            title
          }
          shouldIgnoreSelection
          insights {
            imageUrl
            title
            description
            date(formatString: "DD.MM.YY")
            author
            id
            slug {
              current
            }
          }
        }
        ... on SanityTeamMembersSection {
          _key
          _type
          title
          teamMembers {
            id
            name
            position
            email
            _rawDescription
            mainImage {
              asset {
                fluid(maxWidth: 772) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
        }
        ... on SanityStickyList {
          _key
          _type
          title
          isReverseColumn
          list {
            _key
            title
            text
          }
          mediaItem {
            ... on SanityVideo {
              _type
              _key
              isAutoplay
              video {
                asset {
                  url
                }
              }
              placeholderImage {
                asset {
                  fluid(maxWidth: 1712) {
                    ...GatsbySanityImageFluid_withWebp
                  }
                }
              }
            }
            ... on SanityVideoUrl {
              videoUrl
              isAutoplay
              _type
              _key
              placeholderImage {
                asset {
                  fluid(maxWidth: 1712) {
                    ...GatsbySanityImageFluid_withWebp
                  }
                }
              }
            }
            ... on SanityImage {
              _key
              _type
              asset {
                fluid(maxWidth: 1712) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
        }
        ... on SanityListsWithSublists {
          _key
          _type
          title
          listItems {
            heading
            list
          }
        }
        ... on SanityRelatedPages {
          _key
          _type
          pagesList {
            _key
            _rawBody
            buttonText
            isWorkRelated
            image {
              asset {
                fluid(maxWidth: 772) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            url {
              slug {
                current
              }
            }
          }
        }
        ... on SanityJobsSection {
          _key
          _type
          jobs {
            id
            description
            name
            slug {
              current
            }
          }
        }
        ... on SanityContactCards {
          _key
          _type
          contactCards {
            title
            _key
            personInfo {
              email
              name
              phoneNumber
              profession
              title
              _key
            }
          }
        }
        ... on SanityMapList {
          _key
          _type
          title
          mapList {
            _key
            title
            address
            phoneNumber
            zoom
            mapUrl
            image {
              asset {
                fluid(maxWidth: 772) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            pinPositionX {
              mobile
              tablet
              desktop
            }
            pinPositionY {
              mobile
              tablet
              desktop
            }
          }
        }
      }
    }
  }
`;
